import { UIError } from 'tradingmate-modules/src/models/ui'
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'
import {
  toWidget,
  viewToModelPositionOutsideModelElement
} from '@ckeditor/ckeditor5-widget/src/utils'
import Widget from '@ckeditor/ckeditor5-widget/src/widget'
import Command from '@ckeditor/ckeditor5-core/src/command'
// ui
import {
  addListToDropdown,
  createDropdown
} from '@ckeditor/ckeditor5-ui/src/dropdown/utils'
import Collection from '@ckeditor/ckeditor5-utils/src/collection'
import Model from '@ckeditor/ckeditor5-ui/src/model'

export default class CKEditorVariablePlugin extends Plugin {
  static get requires () {
    return [VariableEditing, VariableUI]
  }
}

class VariableEditing extends Plugin {
  static get requires () {
    return [Widget]
  }

  init () {
    console.log('PlaceholderEditing#init() got called')
    this._defineSchema()
    this._defineConverters()
    this.editor.commands.add('placeholder', new VariableCommand(this.editor))

    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(this.editor.model, (viewElement) =>
        viewElement.hasClass('placeholder')
      )
    )

    this.editor.config.define('placeholderConfig', {
      types: ['date', 'first name', 'surname']
    })
  }

  _defineSchema () {
    // ADDED
    const schema = this.editor.model.schema

    schema.register('placeholder', {
      // Allow wherever text is allowed:
      allowWhere: '$text',

      // The placeholder will act as an inline node:
      isInline: true,

      // The inline widget is self-contained so it cannot be split by the caret and can be selected:
      isObject: true,

      // The inline widget can have the same attributes as text (for example linkHref, bold).
      allowAttributesOf: '$text',

      // The placeholder can have many types, like date, name, surname, etc:
      allowAttributes: ['name']
    })
  }

  _defineConverters () {
    // ADDED
    const conversion = this.editor.conversion

    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        classes: ['placeholder']
      },
      model: (viewElement, { writer: modelWriter }) => {
        // Extract the "name" from "{name}".
        const name = viewElement.getChild(0).data.slice(1, -1)

        return modelWriter.createElement('placeholder', { name })
      }
    })

    conversion.for('editingDowncast').elementToElement({
      model: 'placeholder',
      view: (modelItem, { writer: viewWriter }) => {
        const widgetElement = createPlaceholderView(modelItem, viewWriter)

        // Enable widget handling on a placeholder element inside the editing view.
        return toWidget(widgetElement, viewWriter)
      }
    })

    conversion.for('dataDowncast').elementToElement({
      model: 'placeholder',
      view: (modelItem, { writer: viewWriter }) =>
        createPlaceholderView(modelItem, viewWriter)
    })

    // Helper method for both downcast converters.
    function createPlaceholderView (modelItem, viewWriter) {
      const name = modelItem.getAttribute('name')

      const placeholderView = viewWriter.createContainerElement('span', {
        class: 'placeholder'
      })

      // Insert the placeholder name (as a text).
      const innerText = viewWriter.createText('{' + name + '}')
      viewWriter.insert(
        viewWriter.createPositionAt(placeholderView, 0),
        innerText
      )

      return placeholderView
    }
  }
}

class VariableUI extends Plugin {
  init () {
    const editor = this.editor
    const t = editor.t
    const placeholderNames = editor.config.get('placeholderConfig.types')

    // The "placeholder" dropdown must be registered among the ui components of the editor
    // to be displayed in the toolbar.
    editor.ui.componentFactory.add('placeholder', (locale) => {
      const dropdownView = createDropdown(locale)

      // Populate the list in the dropdown with items.
      addListToDropdown(
        dropdownView,
        getDropdownItemsDefinitions(placeholderNames)
      )

      dropdownView.buttonView.set({
        // The t() function helps localize the editor. All strings enclosed in t() can be
        // translated and change when the language of the editor changes.
        label: t('Placeholder'),
        tooltip: true,
        withText: true
      })

      // Disable the placeholder button when the command is disabled.
      const command = editor.commands.get('placeholder')
      dropdownView.bind('isEnabled').to(command)

      // Execute the command when the dropdown item is clicked (executed).
      this.listenTo(dropdownView, 'execute', (evt) => {
        editor.execute('placeholder', { value: evt.source.commandParam })
        editor.editing.view.focus()
      })

      return dropdownView
    })
  }
}

function getDropdownItemsDefinitions (placeholderNames) {
  const itemDefinitions = new Collection()

  for (const name of placeholderNames) {
    const definition = {
      type: 'button',
      model: new Model({
        commandParam: name,
        label: name,
        withText: true
      })
    }

    // Add the item definition to the collection.
    itemDefinitions.add(definition)
  }

  return itemDefinitions
}

class VariableCommand extends Command {
  execute ({ value }) {
    const editor = this.editor
    const selection = editor.model.document.selection

    editor.model.change((writer) => {
      // Create a <placeholder> elment with the "name" attribute (and all the selection attributes)...
      const placeholder = writer.createElement('placeholder', {
        ...Object.fromEntries(selection.getAttributes()),
        name: value
      })

      // ... and insert it into the document.
      editor.model.insertContent(placeholder)

      // Put the selection on the inserted element.
      writer.setSelection(placeholder, 'on')
    })
  }

  refresh () {
    const model = this.editor.model
    const selection = model.document.selection

    const isAllowed = model.schema.checkChild(
      selection.focus.parent,
      'placeholder'
    )

    this.isEnabled = isAllowed
  }
}
